<template>
  <a-modal
    :visible="visible"
    :title="form.id ? '编辑' : '新增'"
    :mask-closable="false"
    :width="750"
    class="model"
    @ok="handleOk"
    style="top: 20px"
    @cancel="handleCancel"
  >
    <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
      <!-- <a-form-item label="语言" v-bind="validateInfos.language">
        <a-radio-group v-model:value="form.language" :options="languageList" />
      </a-form-item> -->
      <a-form-item label="伙伴名称" v-bind="validateInfos.partnerName">
        <a-input
          v-model:value="form.partnerName"
          placeholder="请输入伙伴姓名"
        />
      </a-form-item>
      <a-form-item label="伙伴类型" v-bind="validateInfos.partnerType">
        <a-select v-model:value="form.partnerType" placeholder="请选择伙伴类型">
          <a-select-option
            v-for="item in partnerTypeList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="伙伴logo（首页）"
        v-bind="validateInfos.partnerHomeLogo"
      >
        <iss-image-upload
          v-model:value="form.partnerHomeLogo"
          :limit-size="10"
          list-type="picture-card"
          accept=".jpe,.jpeg,.jpg,.png"
        />
        <div class="font-color font-size">
          建议上传16:9，大小不超过1M，格式为JPG、JPEG或PNG的图片
        </div>
      </a-form-item>
      <a-form-item label="伙伴logo（二级）" v-bind="validateInfos.partnerLogo">
        <iss-image-upload
          v-model:value="form.partnerLogo"
          :limit-size="10"
          list-type="picture-card"
          accept=".jpe,.jpeg,.jpg,.png"
        />
        <div class="font-color font-size">
          建议上传16:9，大小不超过1M，格式为JPG、JPEG或PNG的图片
        </div>
      </a-form-item>
      <a-form-item label="伙伴链接" v-bind="validateInfos.partnerUrl">
        <a-input v-model:value="form.partnerUrl" placeholder="请输入伙伴链接" />
      </a-form-item>
      <a-form-item label="上传视频" v-bind="validateInfos.partnerVideoUrl">
        <iss-video-upload v-model:value="form.partnerVideoUrl" accept=".mp4" />
        <div class="item-text">请上传视频大小不超过100M,格式为mp4/ogg</div>
      </a-form-item>
      <a-form-item label="伙伴简介" v-bind="validateInfos.partnerContent">
        <a-textarea
          v-model:value="form.partnerContent"
          auto-size
          show-count
          :maxlength="800"
          placeholder="请输入伙伴简介"
        />
      </a-form-item>

      <a-form-item label="排序" v-bind="validateInfos.sort">
        <a-input-number v-model:value="form.sort" :min="1" :max="99" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { reactive, toRaw, toRefs, watch, ref, onBeforeUnmount } from 'vue';
import { Form, Select, InputNumber } from 'ant-design-vue';
import IssImageUpload from '@/components/imageUpload';
import IssVideoUpload from '@/components/videoUpload';
// import { useRoute } from 'vue-router';
// import agendaApi from '@/api/agenda';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    AInputNumber: InputNumber,
    IssImageUpload,
    IssVideoUpload,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    // const route = useRoute();
    // const campaignId = route.params.campaignId;
    const editorRef = ref();
    const required = { required: true, message: '不能为空' };
    const form = reactive({
      id: '',
      partnerName: '',
      language: 'Chinese',
      partnerType: '',
      partnerUrl: '',
      partnerLogo: '',
      partnerHomeLogo: '',
      partnerVideoUrl: '',
      partnerContent: '',
      sort: '',
    });
    const state = reactive({
      agreementList: [],
      agreementDemList: [],
      languageList: [
        { label: '中文', value: 'Chinese' },
        { label: '英文', value: 'English' },
      ],
      partnerTypeList: [
        { value: 0, label: '钻石' },
        { value: 1, label: '黄金' },
        { value: 2, label: '白银' },
      ],
    });
    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      partnerType: [required],
      partnerName: [required],
      partnerHomeLogo: [required],
      partnerLogo: [required],
    });

    watch(
      () => props.initValue,
      initValue => {
        if (initValue.id) {
          Object.assign(form, initValue);
        } else {
          resetFields();
        }
      }
    );

    onBeforeUnmount(() => {
      editorRef.value && editorRef?.value.remove();
    });

    return {
      ...toRefs(state),
      form,
      validateInfos,
      handleCancel: () => context.emit('update:visible', false),
      handleOk: () => {
        validate().then(() => {
          context.emit('fnOk', toRaw(form));
        });
      },
    };
  },
};
</script>
<style scoped lang="less">
/* .model {
  max-height: 600px;
} */
/deep/.ant-col-3 {
  flex: 0 0 19.5%;
  max-width: 19.5%;
}
</style>
